var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "BaseHorizontalDataBar row row--align-center mt-30 mb-30" },
    [
      _c(
        "label",
        {
          staticClass:
            "BaseHorizontalDataBar__small-column fc-light fs-12 uppercase right"
        },
        [_vm._v(_vm._s(_vm.type))]
      ),
      _c("progress", {
        staticClass: "BaseHorizontalDataBar__bar ml-30",
        attrs: { max: _vm.totalShortpays },
        domProps: { value: _vm.count }
      }),
      _c(
        "label",
        {
          staticClass:
            "BaseHorizontalDataBar__small-column fc-light fs-20 center"
        },
        [_vm._v(_vm._s(_vm.count))]
      ),
      _c(
        "label",
        { staticClass: "BaseHorizontalDataBar__small-column fs-20 center" },
        [
          _c("span", { staticClass: "fs-20" }, [
            _vm._v(_vm._s(_vm.totalDollars))
          ]),
          _c("span", { staticClass: "fs-16" }, [_vm._v(_vm._s(_vm.totalCents))])
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "BaseHorizontalDataBar__small-column row row--justify-center"
        },
        [
          _c(
            "label",
            {
              staticClass: "BaseHorizontalDataBar__flag bg-green fc-white fs-12"
            },
            [_vm._v(_vm._s(_vm.percent) + "%")]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }