var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ClientDataGeneral" },
    [
      _c(
        "transition-group",
        { attrs: { name: "Transition__fade" } },
        [
          _vm.requestsProgress.aging
            ? _c("base-aging-chart", {
                key: "aging",
                staticClass: "ClientDataGeneral__aging-content",
                attrs: {
                  cat0to30: {
                    count: _vm.aging.cat0to30,
                    percent: _vm.aging.cat0to30Percent
                  },
                  cat31to45: {
                    count: _vm.aging.cat31to45,
                    percent: _vm.aging.cat31to45Percent
                  },
                  cat46to60: {
                    count: _vm.aging.cat46to60,
                    percent: _vm.aging.cat46to60Percent
                  },
                  cat61to90: {
                    count: _vm.aging.cat61to90,
                    percent: _vm.aging.cat61to90Percent
                  },
                  "cat91-plus": {
                    count: _vm.aging.cat91Plus,
                    percent: _vm.aging.cat91PlusPercent
                  },
                  "cat-total": { count: _vm.aging.catTotal }
                }
              })
            : _vm._e(),
          _vm.requestsProgress.charts
            ? _c("div", { key: "charts", staticClass: "column" }, [
                _c(
                  "label",
                  { staticClass: "ClientDataGeneral__volume-lbl fs-18 fw-med" },
                  [_vm._v("Volume")]
                ),
                _c("canvas", {
                  ref: "volume-chart",
                  staticClass: "ClientDataGeneral__volume-chart"
                }),
                _c(
                  "label",
                  { staticClass: "ClientDataGeneral__volume-lbl fs-18 fw-med" },
                  [_vm._v("Balance")]
                ),
                _c("canvas", { ref: "balances-chart" })
              ])
            : _vm._e()
        ],
        1
      ),
      _c("transition", { attrs: { name: "Transition__fade" } }, [
        _vm.requestsProgress.shortpays
          ? _c(
              "div",
              { staticClass: "column" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "ClientDataGeneral__shortpay-heading row row--align-center row--justify-between"
                  },
                  [
                    _c("label", { staticClass: "fs-18 fw-med" }, [
                      _vm._v("Short Pays")
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "ClientDataGeneral__shortpay-heading-right row row--align-center row--width-auto"
                      },
                      [
                        _c(
                          "label",
                          { staticClass: "fc-light fs-12 uppercase center" },
                          [_vm._v("Number of Invoices")]
                        ),
                        _c(
                          "label",
                          { staticClass: "fc-light fs-12 uppercase center" },
                          [_vm._v("Total")]
                        )
                      ]
                    )
                  ]
                ),
                _vm._l(_vm.shortpays, function(shortpay, index) {
                  return _c("base-horizontal-data-bar", {
                    key: index,
                    attrs: {
                      count: shortpay.count,
                      percent: shortpay.percent,
                      total: shortpay.total,
                      "total-shortpays": _vm.shortpaysTotal,
                      type: shortpay.type
                    }
                  })
                })
              ],
              2
            )
          : _vm._e()
      ]),
      _c("transition", { attrs: { name: "Transition__fade" } }, [
        _vm.requestsProgress.shortpays
          ? _c(
              "div",
              { staticClass: "column" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "ClientDataGeneral__shortpay-heading row row--align-center row--justify-between"
                  },
                  [
                    _c("label", { staticClass: "fs-18 fw-med" }, [
                      _vm._v("Non-payments")
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "ClientDataGeneral__shortpay-heading-right row row--align-center row--width-auto"
                      },
                      [
                        _c(
                          "label",
                          { staticClass: "fc-light fs-12 uppercase center" },
                          [_vm._v("Number of Invoices")]
                        ),
                        _c(
                          "label",
                          { staticClass: "fc-light fs-12 uppercase center" },
                          [_vm._v("Total")]
                        )
                      ]
                    )
                  ]
                ),
                _vm._l(_vm.nonpayments, function(nonpayment, index) {
                  return _c("base-horizontal-data-bar", {
                    key: index,
                    attrs: {
                      count: nonpayment.count,
                      percent: nonpayment.percent,
                      total: nonpayment.total,
                      "total-shortpays": _vm.nonpaymentsTotal,
                      type: nonpayment.type
                    }
                  })
                })
              ],
              2
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }