<template>
  <div class="ClientDataGeneral">
    <!-- VOLUME AND BALANCE -->
    <transition-group name="Transition__fade">
      <base-aging-chart
        v-if="requestsProgress.aging"
        :cat0to30="{ count: aging.cat0to30, percent: aging.cat0to30Percent }"
        :cat31to45="{ count: aging.cat31to45, percent: aging.cat31to45Percent }"
        :cat46to60="{ count: aging.cat46to60, percent: aging.cat46to60Percent }"
        :cat61to90="{ count: aging.cat61to90, percent: aging.cat61to90Percent }"
        :cat91-plus="{ count: aging.cat91Plus, percent: aging.cat91PlusPercent }"
        :cat-total="{ count: aging.catTotal }"
        class="ClientDataGeneral__aging-content"
        key="aging"
      />
      <div
        v-if="requestsProgress.charts"
        class="column"
        key="charts"
      >
        <label class="ClientDataGeneral__volume-lbl fs-18 fw-med">Volume</label>
        <canvas
          ref="volume-chart"
          class="ClientDataGeneral__volume-chart"
        />
        <label class="ClientDataGeneral__volume-lbl fs-18 fw-med">Balance</label>
        <canvas ref="balances-chart" />
      </div>
    </transition-group>

    <!-- SHORTPAYS -->
    <transition name="Transition__fade">
      <div
        v-if="requestsProgress.shortpays"
        class="column"
      >
        <div class="ClientDataGeneral__shortpay-heading row row--align-center row--justify-between">
          <label class="fs-18 fw-med">Short Pays</label>

          <div class="ClientDataGeneral__shortpay-heading-right row row--align-center row--width-auto">
            <label class="fc-light fs-12 uppercase center">Number of Invoices</label>
            <label class="fc-light fs-12 uppercase center">Total</label>
          </div>
        </div>

        <base-horizontal-data-bar
          v-for="(shortpay, index) in shortpays"
          :key="index"
          :count="shortpay.count"
          :percent="shortpay.percent"
          :total="shortpay.total"
          :total-shortpays="shortpaysTotal"
          :type="shortpay.type"
        />
      </div>
    </transition>

    <!-- NON-PAYMENTS -->
    <transition name="Transition__fade">
      <div
        v-if="requestsProgress.shortpays"
        class="column"
      >
        <div class="ClientDataGeneral__shortpay-heading row row--align-center row--justify-between">
          <label class="fs-18 fw-med">Non-payments</label>

          <div class="ClientDataGeneral__shortpay-heading-right row row--align-center row--width-auto">
            <label class="fc-light fs-12 uppercase center">Number of Invoices</label>
            <label class="fc-light fs-12 uppercase center">Total</label>
          </div>
        </div>

        <base-horizontal-data-bar
          v-for="(nonpayment, index) in nonpayments"
          :key="index"
          :count="nonpayment.count"
          :percent="nonpayment.percent"
          :total="nonpayment.total"
          :total-shortpays="nonpaymentsTotal"
          :type="nonpayment.type"
        />
      </div>
    </transition>
  </div>
</template>

<script>
// Packages
import Chart from 'chart.js'
import moment from 'moment'
// Helpers
import {
  Balance,
  Invoice
} from '../../../../utils/api'
// Components
import BaseAgingChart from '../../../../components/base-aging-chart.vue'
import BaseHorizontalDataBar from '../../../../components/base-horizontal-data-bar.vue'

export default {
  name: 'ClientDataGeneral',

  components: {
    BaseAgingChart,
    BaseHorizontalDataBar,
  },

  async created () {
    this.currentMonth = moment().month()
    this.progressStart()
    await this.getAgingData()
    await this.constructCharts()
    await this.getInvoiceUpdateStats()
    this.progressFinish()
  },

  beforeRouteLeave (to, from, next) {
    if (this.charts.balances) this.charts.balances.destroy()
    if (this.charts.volume) this.charts.volume.destroy()
    next()
  },

  data () {
    return {
      aging: {
        cat0to30: null,
        cat0to30Percent: null,
        cat31to45: null,
        cat31to45Percent: null,
        cat46to60: null,
        cat46to60Percent: null,
        cat61to90: null,
        cat61to90Percent: null,
        cat91Plus: null,
        cat91PlusPercent: null,
        catTotal: null,
      },
      chartData: {
        balances: null,
        volume: null,
      },
      chartDataRaw: { // For double checking data points ONLY
        balances: null,
        volume: null,
      },
      charts: {
        balances: null,
        volume: null,
      },
      currentMonth: null,
      nonpayments: null,
      nonpaymentsTotal: null,
      paidInvoices: [],
      requestsProgress: {
        aging: false,
        charts: false,
        nonpayments: false,
        shortpays: true,
      },
      shortpays: null,
      shortpaysTotal: null,
    }
  },

  computed: {
    client () {
      return this.$store.getters.client
    },
  },

  methods: {
    agingValueText (age, decimal = false) {
      return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
        .format(age)
        .split('.')[decimal ? 1 : 0]
    },

    async constructCharts () {
      const monthAbbr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
      const chartLabelByCurrentMonth = [
        ...monthAbbr.slice(this.currentMonth + 1),
        ...monthAbbr.slice(0, this.currentMonth + 1)
      ]

      try {
        await this.getChartData()
        // `await` is required to ensure requestsProgress the UI updates with the chart canvases
        await this.$set(this.requestsProgress, 'charts', true)
        this.constructVolumeChart(chartLabelByCurrentMonth)
        this.constructBalancesChart(chartLabelByCurrentMonth)
      }
      catch (error) {
        this.CError(error)
        this.requestFailure({ message: 'There was an issue constructing the charts' })
      }
    },

    constructBalancesChart (labels) {
      // Y axis labels: $ amounts
      // X axis labels: Months (abbreviated), current month last
      if (!this.$refs['balances-chart']) return // Avoids Toastr errors when switching data views
      const ctx = this.$refs['balances-chart'].getContext('2d')
      const fillGradient = ctx.createLinearGradient(0, 0, 0, 500)
      // Add three color stops
      fillGradient.addColorStop(0, 'rgba(51, 172, 18, .1)')
      fillGradient.addColorStop(1, 'rgba(255, 255, 255, .1)')

      // Because stupid fucking eSLINT
      // eslint-disable-next-line no-new
      this.$set(this.charts, 'balances', new Chart(ctx, {
        type: 'line',
        data: {
          labels,
          datasets: [{
            data: this.chartData.balances,
            backgroundColor: fillGradient,
            borderColor: '#33AC12',
            borderWidth: 2,
            fill: true,
            lineTension: 0,
            pointBackgroundColor: '#33AC12',
            pointBorderColor: '#FFF',
            pointBorderWidth: 2,
            pointHitRadius: 15,
            pointRadius: 4,
          }],
        },
        options: {
          defaultFontColor: '#9EA0A5',
          defaultFontFamily: "'Roboto', 'sans-serif'",
          legend: {
            display: false,
          },
          responsive: true,
          scales: {
            xAxes: [{
              gridLines: {
                display: false,
                drawBorder: false,
              },
              ticks: {
                fontColor: '#9EA0A5',
                fontFamily: "'Roboto', 'sans-serif'",
                padding: 21,
              },
            }],
            yAxes: [{
              gridLines: {
                color: '#EAEDF3',
                drawBorder: false,
                drawTicks: false,
              },
              ticks: {
                callback: (value) => {
                  if (value >= 1000) {
                    return `$ ${Number(value / 1000).toFixed(2)}K`
                  }
                  return `$ ${Number(value).toFixed(2)}`
                },
                fontColor: '#9EA0A5',
                fontFamily: "'Roboto', 'sans-serif'",
                padding: 27,
              },
            }],
          },
          title: {
            display: false,
          },
        }
      }))
    },

    constructVolumeChart (labels) {
      // Y axis labels: $ amounts
      // X axis labels: Months (abbreviated), current month last
      if (!this.$refs['volume-chart']) return // Avoids Toastr errors when switching data views
      const ctx = this.$refs['volume-chart'].getContext('2d')
      const fillGradient = ctx.createLinearGradient(0, 0, 0, 500)
      // Add three color stops
      fillGradient.addColorStop(0, 'rgba(26, 144, 235, .1)')
      fillGradient.addColorStop(1, 'rgba(255, 255, 255, .1)')

      // Because stupid fucking ESLINT
      // eslint-disable-next-line no-new
      this.$set(this.charts, 'volume', new Chart(ctx, {
        type: 'line',
        data: {
          labels,
          datasets: [{
            data: this.chartData.volume,
            backgroundColor: fillGradient,
            borderColor: '#1991EB',
            borderWidth: 2,
            fill: true,
            lineTension: 0,
            pointBackgroundColor: '#2CA0F7',
            pointBorderColor: '#FFF',
            pointBorderWidth: 2,
            pointHitRadius: 15,
            pointRadius: 4,
          }],
        },
        options: {
          defaultFontColor: '#9EA0A5',
          defaultFontFamily: "'Roboto', 'sans-serif'",
          legend: {
            display: false,
          },
          responsive: true,
          scales: {
            xAxes: [{
              gridLines: {
                display: false,
                drawBorder: false,
              },
              ticks: {
                fontColor: '#9EA0A5',
                fontFamily: "'Roboto', 'sans-serif'",
                padding: 21,
              },
            }],
            yAxes: [{
              gridLines: {
                color: '#EAEDF3',
                drawBorder: false,
                drawTicks: false,
              },
              ticks: {
                callback: (value) => {
                  if (value >= 1000) {
                    return `$ ${Number(value / 1000).toFixed(2)}K`
                  }
                  return `$ ${Number(value).toFixed(2)}`
                },
                fontColor: '#9EA0A5',
                fontFamily: "'Roboto', 'sans-serif'",
                padding: 27,
              },
            }],
          },
          title: {
            display: false,
          },
        }
      }))
    },

    async getCat0to30Total () {
      const to = moment().startOf('day').subtract(30, 'days').toDate()
      try {
        const result = (await Invoice.queryList({
          client_id: this.client.id,
          gt___approved_date: to,
          status: 'approved'
        }, 1)).data

        return result.facets_stats ? (result.facets_stats.total_amount.sum / 100) : 0
      }
      catch (error) {
        this.captureSentryEvent(
          'Client Data General "getCat0to30Total"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
            to,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting total values' })
      }
    },

    async getCat31to45Total () {
      const from = moment().startOf('day').subtract(31, 'days').toDate()
      const to = moment().startOf('day').subtract(45, 'days').toDate()
      try {
        const result = (await Invoice.queryList({
          client_id: this.client.id,
          gt___approved_date: to,
          lt___approved_date: from,
          status: 'approved'
        }, 1)).data

        return result.facets_stats ? (result.facets_stats.total_amount.sum / 100) : 0
      }
      catch (error) {
        this.captureSentryEvent(
          'Client Data General "getCat31to45Total"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            from,
            props: this.$props,
            response: error.response,
            to,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting total values' })
      }
    },

    async getCat46to60Total () {
      const from = moment().startOf('day').subtract(46, 'days').toDate()
      const to = moment().startOf('day').subtract(60, 'days').toDate()
      try {
        const result = (await Invoice.queryList({
          client_id: this.client.id,
          gt___approved_date: to,
          lt___approved_date: from,
          status: 'approved'
        }, 1)).data

        return result.facets_stats ? (result.facets_stats.total_amount.sum / 100) : 0
      }
      catch (error) {
        this.captureSentryEvent(
          'Client Data General "getCat46to60Total"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            from,
            props: this.$props,
            response: error.response,
            to,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting total values' })
      }
    },

    async getCat61to90Total () {
      const from = moment().startOf('day').subtract(61, 'days').toDate()
      const to = moment().startOf('day').subtract(90, 'days').toDate()
      try {
        const result = (await Invoice.queryList({
          client_id: this.client.id,
          gt___approved_date: to,
          lt___approved_date: from,
          status: 'approved'
        }, 1)).data

        return result.facets_stats ? (result.facets_stats.total_amount.sum / 100) : 0
      }
      catch (error) {
        this.captureSentryEvent(
          'Client Data General "getCat61to90Total"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            from,
            props: this.$props,
            response: error.response,
            to,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting total values' })
      }
    },

    async getCat91PlusTotal () {
      const from = moment().startOf('day').subtract(91, 'days').toDate()
      try {
        const result = (await Invoice.queryList({
          client_id: this.client.id,
          lt___approved_date: from,
          status: 'approved'
        }, 1)).data

        return result.facets_stats ? (result.facets_stats.total_amount.sum / 100) : 0
      }
      catch (error) {
        this.captureSentryEvent(
          'Client Data General "getCat90PlusTotal"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            from,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting total values' })
      }
    },

    async getCatTotal () {
      try {
        const result = (await Invoice.queryList({
          client_id: this.client.id,
          status: 'approved'
        }, 1)).data
        return result.facets_stats ? (result.facets_stats.total_amount.sum / 100) : 0
      }
      catch (error) {
        this.captureSentryEvent(
          'Client Data General "getCatTotal"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting total values' })
      }
    },

    async getAgingData () {
      try {
        // DIVIDE approved invoice ammounts into aging categories and total each category
        // 'cat' is short for 'category'
        const cat0to30Total = await this.getCat0to30Total()
        const cat31to45Total = await this.getCat31to45Total()
        const cat46to60Total = await this.getCat46to60Total()
        const cat61to90Total = await this.getCat61to90Total()
        const cat91PlusTotal = await this.getCat91PlusTotal()
        const catTotal = await this.getCatTotal()

        const cat0to30Percent = (cat0to30Total / catTotal * 100).toFixed(2)
        const cat31to45Percent = (cat31to45Total / catTotal * 100).toFixed(2)
        const cat46to60Percent = (cat46to60Total / catTotal * 100).toFixed(2)
        const cat61to90Percent = (cat61to90Total / catTotal * 100).toFixed(2)
        const cat91PlusPercent = (cat91PlusTotal / catTotal * 100).toFixed(2)

        this.$set(this.aging, 'cat0to30', cat0to30Total)
        this.$set(this.aging, 'cat0to30Percent', isNaN(cat0to30Percent) ? 0 : cat0to30Percent)
        this.$set(this.aging, 'cat31to45', cat31to45Total)
        this.$set(this.aging, 'cat31to45Percent', isNaN(cat31to45Percent) ? 0 : cat31to45Percent)
        this.$set(this.aging, 'cat46to60', cat46to60Total)
        this.$set(this.aging, 'cat46to60Percent', isNaN(cat46to60Percent) ? 0 : cat46to60Percent)
        this.$set(this.aging, 'cat61to90', cat61to90Total)
        this.$set(this.aging, 'cat61to90Percent', isNaN(cat61to90Percent) ? 0 : cat61to90Percent)
        this.$set(this.aging, 'cat91Plus', cat91PlusTotal)
        this.$set(this.aging, 'cat91PlusPercent', isNaN(cat91PlusPercent) ? 0 : cat91PlusPercent)
        this.$set(this.aging, 'catTotal', catTotal)

        this.$set(this.requestsProgress, 'aging', true)
      }
      catch (error) {
        this.CError(error)
        this.requestFailure({ message: 'There was an issue setting up the aging data' })
      }
    },

    async getChartData () {
      // Balance Data
      try {
        const balances = (await Balance.getClientBalances({
          client_id: this.client.id,
          sort_by: 'accounting',
        })).data.rows

        // Divide balances into months using their created_at date
        const months = {}
        balances.forEach(balance => {
          const whatMonth = this.numberToMonth(moment(balance.created_at).month())
          if (!months[whatMonth]) {
            months[whatMonth] = {
              average: null,
              balances: [],
              total: null,
            }
          }
          months[whatMonth].balances.push(balance)
        })

        // Total and average each month's balances
        // If there weren't any balances in a month, set total to 0
        for (let i = 0; i <= 11; i++) {
          if (months[this.numberToMonth(i)]) {
            // Month exists
            months[this.numberToMonth(i)].total = months[this.numberToMonth(i)].balances
              .reduce((acc, cur) => acc + cur.total, 0)
              .toFixed(2)
            months[this.numberToMonth(i)].average = (
              months[this.numberToMonth(i)].balances
                .reduce((acc, cur) => acc + cur.total, 0)
              / months[this.numberToMonth(i)].balances.length
            ).toFixed(2)
          } else {
            // Month does NOT exist
            months[this.numberToMonth(i)] = { average: 0, balances: [], total: 0 }
          }
        }

        // Order the months data into an array from january's to december's data
        const calendarMonths = ['jan', 'feb', 'mar', 'apr', 'may', 'june', 'july', 'aug', 'sept', 'oct', 'nov', 'dec']
        const orderedMonths = []
        calendarMonths.forEach(month => orderedMonths.push(months[month].average))

        // Reorder the data to match chart labels (our current month is last on the x-axis)
        const reorderedMonths = [
          ...orderedMonths.slice(this.currentMonth + 1),
          ...orderedMonths.slice(0, this.currentMonth + 1)
        ]

        this.$set(this.chartDataRaw, 'balances', months)
        this.$set(this.chartData, 'balances', reorderedMonths)
      }
      catch (error) {
        this.captureSentryEvent(
          'Client Data General "Get Chart Data: Balance"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting the balance data' })
      }


      // Volume Data
      try {
        // GET volume data for each month of the last rolling year
        this.paidInvoices = (await Invoice.queryList({
          client_id: this.client.id,
          gt___paid_date: moment().subtract(1, 'years').toDate(),
          status: 'paid',
        }, 1000)).data.rows

        // Divide paidInvoices into months
        const months = {}
        this.paidInvoices.forEach(invoice => {
          const whatMonth = this.numberToMonth(moment(invoice.approved_date).month())
          if (!months[whatMonth]) {
            months[whatMonth] = {
              invoices: [],
              total: null,
            }
          }
          months[whatMonth].invoices.push(invoice)
        })
        // Total value for each month in months
        for (let i = 0; i <= 11; i++) {
          if (months[this.numberToMonth(i)]) {
            // Month exists
            months[this.numberToMonth(i)].total = months[this.numberToMonth(i)].invoices
              .reduce((acc, cur) => acc + cur.total_amount, 0)
              .toFixed(2)
          } else {
            // Month does NOT exist
            months[this.numberToMonth(i)] = { invoices: [], total: 0 }
          }
        }

        // Order the months data into an array from january's to december's data
        const orderedMonths = []
        const calendarMonths = ['jan', 'feb', 'mar', 'apr', 'may', 'june', 'july', 'aug', 'sept', 'oct', 'nov', 'dec']
        calendarMonths.forEach(month => orderedMonths.push(months[month].total))

        // Reorder the data to match chart labels (our current month is last on the x-axis)
        const reorderedMonths = [
          ...orderedMonths.slice(this.currentMonth + 1),
          ...orderedMonths.slice(0, this.currentMonth + 1)
        ]

        this.$set(this.chartDataRaw, 'volume', months)
        this.$set(this.chartData, 'volume', reorderedMonths)
      }
      catch (error) {
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting the volume data' })
      }
    },

    async getInvoiceUpdateStats () {
      try {
        const nonpayments = (await Invoice.getUpdateStats({
          client_id: this.client.id,
          gt___approved_date: moment().subtract(1, 'years').format('MM/DD/YYYY'),
          invoiceupdatestatus: 'nonpayment',
        })).data
        const shortpays = (await Invoice.getUpdateStats({
          client_id: this.client.id,
          gt___approved_date: moment().subtract(1, 'years').format('MM/DD/YYYY'),
          invoiceupdatestatus: 'shortpay',
        })).data
        this.CDir(nonpayments, shortpays)

        // Store the data flatly && calculate the percentages

        this.nonpaymentsTotal = Object
          .keys(nonpayments)
          .reduce((acc, cur) => acc + nonpayments[cur].count, 0)
        this.shortpaysTotal = Object
          .keys(shortpays)
          .reduce((acc, cur) => acc + shortpays[cur].count, 0)

        // Data looks like "[reason]": { count: X, total: { total_amount: XXXXX }}
        const flatNonpayments = Object.keys(nonpayments).map(type => {
          return {
            count: nonpayments[type].count,
            percent: this.nonpaymentsTotal
              ? Number(nonpayments[type].count / this.nonpaymentsTotal * 100).toFixed(2)
              : '0',
            total: nonpayments[type].total.total_amount,
            type,
          }
        })
        const flatShortpays = Object.keys(shortpays).map(type => {
          return {
            count: shortpays[type].count,
            percent: this.shortpaysTotal
              ? Number(shortpays[type].count / this.shortpaysTotal * 100).toFixed(2)
              : '0',
            total: shortpays[type].total.total_amount,
            type,
          }
        })

        this.nonpayments = flatNonpayments
        this.$set(this.requestsProgress, 'nonpayments', true)
        this.shortpays = flatShortpays
        this.$set(this.requestsProgress, 'shortpays', true)
      }
      catch (error) {
        this.captureSentryEvent(
          'Client Data General "Get Invoice Update Stats"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({
          message: 'There was an issue getting the client\'s shortpay and non-payment data'
        })
      }
    },

    numberToMonth (number) {
      if (number === 0) return 'jan'
      if (number === 1) return 'feb'
      if (number === 2) return 'mar'
      if (number === 3) return 'apr'
      if (number === 4) return 'may'
      if (number === 5) return 'june'
      if (number === 6) return 'july'
      if (number === 7) return 'aug'
      if (number === 8) return 'sept'
      if (number === 9) return 'oct'
      if (number === 10) return 'nov'
      if (number === 11) return 'dec'
    },
  },
}
</script>

<style lang="sass">
.ClientDataGeneral

  &__percent-lbl
    display: inline-block
    padding: rem(3px) rem(7px)

  &__shortpay-heading
    margin-top: rem(50px)

  &__shortpay-heading-right
    margin-right: rem(130px)

    label
      width: rem(130px) // matches horizontal data bar __small-column

  &__volume-chart
    width: 80%

  &__volume-lbl
    margin-top: rem(33px)
    margin-bottom: rem(40px)
</style>