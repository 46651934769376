<template>
  <div class="BaseHorizontalDataBar row row--align-center mt-30 mb-30">
    <label class="BaseHorizontalDataBar__small-column fc-light fs-12 uppercase right">{{ type }}</label>

    <progress
      class="BaseHorizontalDataBar__bar ml-30"
      :max="totalShortpays"
      :value="count"
    />

    <label class="BaseHorizontalDataBar__small-column fc-light fs-20 center">{{ count }}</label>
    <label class="BaseHorizontalDataBar__small-column fs-20 center">
      <span class="fs-20">{{ totalDollars }}</span>
      <span class="fs-16">{{ totalCents }}</span>
    </label>
    <div class="BaseHorizontalDataBar__small-column row row--justify-center">
      <label class="BaseHorizontalDataBar__flag bg-green fc-white fs-12">{{ percent }}%</label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseHorizontalDataBar',

  props: {
    count: {
      type: Number,
      required: true,
    },
    percent: {
      type: String,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    totalShortpays: {
      type: Number,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },

  computed: {
    totalCents () {
      const formatted = Intl
        .NumberFormat('en-US', { style: 'currency', currency: 'USD' })
        .format(this.total / 100)
      return formatted.slice(formatted.indexOf('.'))
    },

    totalDollars () {
      const formatted = Intl
        .NumberFormat('en-US', { style: 'currency', currency: 'USD' })
        .format(this.total / 100)
      return formatted.slice(0, formatted.indexOf('.'))
    },
  },
}
</script>

<style lang="sass">
.BaseHorizontalDataBar

  // IE fallback
  progress[value]
    color: $branding

  // Chrome/Safari/Opera value
  progress[value]::-webkit-progress-value
    background-color: $branding

  // Chrome/Safari/Operate container
  progress[value]::-webkit-progress-bar
    background-color: #EAECEE

  // Firefox value
  progress[value]::-moz-progress-bar
    background-color: $branding

  // Firefox doesn't have a pseudo class for the container
  &__bar
    +flex($basis: 0, $grow: 1, $shrink: 0)
    background-color: #EAECEE
    border-radius: 0
    box-shadow: none
    height: rem(11px)

  &__flag
    padding: rem(3px) rem(10px)

  &__small-column
    width: rem(130px)
</style>